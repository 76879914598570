// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---content-blog-blending-images-opencv-blending-images-opencv-md": () => import("./../../../content/blog/blending-images-opencv/blending-images-opencv.md" /* webpackChunkName: "component---content-blog-blending-images-opencv-blending-images-opencv-md" */),
  "component---content-blog-conda-to-the-point-conda-to-the-point-md": () => import("./../../../content/blog/conda-to-the-point/conda-to-the-point.md" /* webpackChunkName: "component---content-blog-conda-to-the-point-conda-to-the-point-md" */),
  "component---content-blog-patient-flow-mimic-iii-components-heatmapsvg-js": () => import("./../../../content/blog/patient-flow-mimic-iii/components/heatmapsvg.js" /* webpackChunkName: "component---content-blog-patient-flow-mimic-iii-components-heatmapsvg-js" */),
  "component---content-blog-patient-flow-mimic-iii-components-patient-flow-mimic-heat-map-diagram-js": () => import("./../../../content/blog/patient-flow-mimic-iii/components/patientFlowMimicHeatMapDiagram.js" /* webpackChunkName: "component---content-blog-patient-flow-mimic-iii-components-patient-flow-mimic-heat-map-diagram-js" */),
  "component---content-blog-patient-flow-mimic-iii-components-patient-flow-mimic-sankey-diagram-js": () => import("./../../../content/blog/patient-flow-mimic-iii/components/patientFlowMimicSankeyDiagram.js" /* webpackChunkName: "component---content-blog-patient-flow-mimic-iii-components-patient-flow-mimic-sankey-diagram-js" */),
  "component---content-blog-patient-flow-mimic-iii-components-sankeysvg-js": () => import("./../../../content/blog/patient-flow-mimic-iii/components/sankeysvg.js" /* webpackChunkName: "component---content-blog-patient-flow-mimic-iii-components-sankeysvg-js" */),
  "component---content-blog-patient-flow-mimic-iii-patient-flow-mdx": () => import("./../../../content/blog/patient-flow-mimic-iii/patient-flow.mdx" /* webpackChunkName: "component---content-blog-patient-flow-mimic-iii-patient-flow-mdx" */),
  "component---content-blog-ssh-ubuntu-virtualbox-post-md": () => import("./../../../content/blog/ssh-ubuntu-virtualbox/post.md" /* webpackChunkName: "component---content-blog-ssh-ubuntu-virtualbox-post-md" */),
  "component---content-blog-sshfs-ubuntu-virtualbox-post-md": () => import("./../../../content/blog/sshfs-ubuntu-virtualbox/post.md" /* webpackChunkName: "component---content-blog-sshfs-ubuntu-virtualbox-post-md" */),
  "component---content-blog-updating-macos-terminal-color-scheme-updating-macos-terminal-color-scheme-md": () => import("./../../../content/blog/updating-macos-terminal-color-scheme/updating-macos-terminal-color-scheme.md" /* webpackChunkName: "component---content-blog-updating-macos-terminal-color-scheme-updating-macos-terminal-color-scheme-md" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

