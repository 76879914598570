import React from "react";

const SankeySVG = () => (
  <svg className="sankey" style={{
        position: "absolute",
        left: "0px",
        top: "0px",
        zIndex: "2" }}>
  </svg>
)

export default SankeySVG

