import React from "react";
import { graphql } from "gatsby";
import "../../../../src/scss/kollstrap.scss";
import { CircleLoader } from "react-spinners";

import * as d3v3 from "d3v3";
import SankeySVG from "./sankeysvg";
import { rhythm, scale } from "../../../../src/utils/typography";

class PatientFlowMimicSankeyDiagram extends React.Component {
  componentDidMount() {
    this.drawChart();
  }
  drawChart() {
    this.sankeyViz(d3v3);
  }
  render() {
    return (
      <div className="row">
        <div className="col">
          <div
            id="sankey"
            className="sankdiv sankey mb-3"
            style={{ position: "relative" }}
          >
            <canvas></canvas>
            <SankeySVG />
          </div>
        </div>
      </div>
    );
  }

  sankeyViz(d3) {
    const sankeyDivHeight = 500;
    var svg = d3.select("#sankey svg");
    var selectedCanvas = d3.select('canvas');
    let chartDiv = document.getElementById("sankey");
    let sankeyDivWidth = chartDiv.clientWidth < 1000 ? chartDiv.clientWidth : 1000;

    /*
     * Dynamically resize canvas
     */
    (function() {
      // Start listening to resize events and draw canvas.
      // Register an event listener to call the resizeCanvas() function
      window.addEventListener('resize', resizeCanvas, false);
      resizeCanvas();
    })();

    function redraw() {
      svg.selectAll("*").remove();
      const margin = { top: 1, right: 1, bottom: 6, left: 1 };
      sankeyDivWidth = chartDiv.clientWidth < 1000 ? chartDiv.clientWidth : 1000;
      const width = sankeyDivWidth - margin.left - margin.right;
      const height = sankeyDivHeight - margin.top - margin.bottom;
      const sankeyDataUrl = "https://curiousstemlovingfellow-blog-data.s3.amazonaws.com/blog-posts/sankey.json";

      var layout = function () {
        var sankey = {},
            nodeWidth = 24,
            nodePadding = 8,
            size = [1, 1],
            nodes = [],
            links = [];

        sankey.nodeWidth = function (_) {
          if (!arguments.length) return nodeWidth;
          nodeWidth = +_;
          return sankey;
        };

        sankey.nodePadding = function (_) {
          if (!arguments.length) return nodePadding;
          nodePadding = +_;
          return sankey;
        };

        sankey.nodes = function (_) {
          if (!arguments.length) return nodes;
          nodes = _;
          return sankey;
        };

        sankey.links = function (_) {
          if (!arguments.length) return links;
          links = _;
          return sankey;
        };

        sankey.size = function (_) {
          if (!arguments.length) return size;
          size = _;
          return sankey;
        };

        sankey.layout = function (iterations) {
          computeNodeLinks();
          computeNodeValues();
          computeNodeBreadths();
          computeNodeDepths(iterations);
          computeLinkDepths();
          return sankey;
        };

        sankey.relayout = function () {
          computeLinkDepths();
          return sankey;
        };

        sankey.link = function () {
          var curvature = 0.5;

          function link(d) {
            var x0 = d.source.x + d.source.dx,
                x1 = d.target.x,
                xi = d3.interpolateNumber(x0, x1),
                x2 = xi(curvature),
                x3 = xi(1 - curvature),
                y0 = d.source.y + d.sy + d.dy / 2,
                y1 = d.target.y + d.ty + d.dy / 2;
            return (
                "M" +
                x0 +
                "," +
                y0 +
                "C" +
                x2 +
                "," +
                y0 +
                " " +
                x3 +
                "," +
                y1 +
                " " +
                x1 +
                "," +
                y1
            );
          }

          link.curvature = function (_) {
            if (!arguments.length) return curvature;
            curvature = +_;
            return link;
          };

          return link; // Return the function
        };

        // Populate the sourceLinks and targetLinks for each node.
        // Also, if the source and target are not objects, assume they are indices.
        function computeNodeLinks() {
          //console.log('BEFORE COMPUTING NODE LINKS: ', nodes, links);
          nodes.forEach(function (node) {
            // Create the source and target links
            node.sourceLinks = [];
            node.targetLinks = [];
          });
          links.forEach(function (link) {
            // acquire the value of the source & target and populate and populate source & target links
            var source = link.source,
                target = link.target;
            if (typeof source === "number")
              source = link.source = nodes[link.source];
            if (typeof target === "number")
              target = link.target = nodes[link.target];
            source.sourceLinks.push(link); // Push source links to array defined above
            target.targetLinks.push(link); // Push target links to array defined above
          });
          //console.log('AFTER COMPUTING NODE LINKS: ', nodes, links);
        }

        // Compute the value (size) of each node by summing the associated links.
        function computeNodeValues() {
          nodes.forEach(function (node) {
            // console.log('VALUE FOR EACH IN NODE: ', value);
            node.value = Math.max(
                d3.sum(node.sourceLinks, value),
                d3.sum(node.targetLinks, value)
            );
            //console.log('NODE VALUE AFTER MAXING: ', node.value);
          }); //console.log('AFTER COMPUTING NODE VALUES: ', nodes);
        }

        // Iteratively assign the breadth (x-position) for each node.
        // Nodes are assigned the maximum breadth of incoming neighbors plus one;
        // nodes with no incoming links are assigned breadth zero, while
        // nodes with no outgoing links are assigned the maximum breadth.
        function computeNodeBreadths() {
          // Compute the x position of the nodes
          var remainingNodes = nodes,
              nextNodes,
              x = 0;

          while (remainingNodes.length) {
            //console.log('REMAINING NODES: ', remainingNodes);
            nextNodes = [];
            remainingNodes.forEach(function (node) {
              // Since remaining nodes is passed by address to nodes,
              // the following will map values to nodes. After it is reassigned the new assigned
              // don't matter since it will be erased.

              // Only matters first iteration (remainingNodes = nodes). Values will be saved to "nodes"
              // Doesn't matter after remainingNodes is reassigned (remainingNodes = nextNodes)
              node.x = x;
              node.dx = nodeWidth;
              node.sourceLinks.forEach(function (link) {
                // For each node in source link
                if (nextNodes.indexOf(link.target) < 0) {
                  //console.log('LINK TARGET: ', link.target);
                  nextNodes.push(link.target);
                }
              });
            });
            remainingNodes = nextNodes;
            ++x; // Purpose of this while loop function is to get value of X
          }
          //console.log('NEXT NODES: ', nextNodes);
          moveSinksRight(x);
          // console.log('SIZE[0]: ', size[0]);
          // console.log('SIZE [0] - Node Width: ', (size[0] - nodeWidth));
          // console.log('X - 1', x-1);
          // console.log('RESULT: ', (size[0] - nodeWidth) / (x - 1));
          scaleNodeBreadths((size[0] - nodeWidth) / (x - 1));
        }

        function moveSourcesRight() {
          nodes.forEach(function (node) {
            if (!node.targetLinks.length) {
              node.x =
                  d3.min(node.sourceLinks, function (d) {
                    return d.target.x;
                  }) - 1;
            }
          });
        }

        function moveSinksRight(x) {
          nodes.forEach(function (node) {
            if (!node.sourceLinks.length) {
              node.x = x - 1;
            }
          });
        }

        function scaleNodeBreadths(kx) {
          nodes.forEach(function (node) {
            node.x *= kx;
          });
        }

        function computeNodeDepths(iterations) {
          // Beast operation
          // console.log("NODES: ", nodes);
          var nodesByBreadth = d3
              .nest() // Create an array of nodes by breadth. Group same breadth together
              .key(function (d) {
                // console.log("D KEY: ", d);
                return d.x;
              })
              .sortKeys(d3.ascending)
              .entries(nodes)
              .map(function (d) {
                /*console.log('D MAPPING: ', d)*/
                return d.values;
              });
          //console.log('NODE BY BREADTH: ', nodesByBreadth);
          //
          initializeNodeDepth();
          resolveCollisions();
          for (var alpha = 1; iterations > 0; --iterations) {
            relaxRightToLeft((alpha *= 0.99));
            resolveCollisions();
            relaxLeftToRight(alpha);
            resolveCollisions();
          }

          function initializeNodeDepth() {
            var ky = d3.min(nodesByBreadth, function (nodes) {
              return (
                  (size[1] - (nodes.length - 1) * nodePadding) / d3.sum(nodes, value)
              );
            });

            nodesByBreadth.forEach(function (nodes) {
              nodes.forEach(function (node, i) {
                node.y = i;
                node.dy = node.value * ky;
              });
            });

            links.forEach(function (link) {
              link.dy = link.value * ky;
            });
          }

          function relaxLeftToRight(alpha) {
            nodesByBreadth.forEach(function (nodes, breadth) {
              nodes.forEach(function (node) {
                if (node.targetLinks.length) {
                  var y =
                      d3.sum(node.targetLinks, weightedSource) /
                      d3.sum(node.targetLinks, value);
                  node.y += (y - center(node)) * alpha;
                }
              });
            });

            function weightedSource(link) {
              return center(link.source) * link.value;
            }
          }

          function relaxRightToLeft(alpha) {
            nodesByBreadth
                .slice()
                .reverse()
                .forEach(function (nodes) {
                  nodes.forEach(function (node) {
                    if (node.sourceLinks.length) {
                      var y =
                          d3.sum(node.sourceLinks, weightedTarget) /
                          d3.sum(node.sourceLinks, value);
                      node.y += (y - center(node)) * alpha;
                    }
                  });
                });

            function weightedTarget(link) {
              return center(link.target) * link.value;
            }
          }

          function resolveCollisions() {
            nodesByBreadth.forEach(function (nodes) {
              var node,
                  dy,
                  y0 = 0,
                  n = nodes.length,
                  i;

              // Push any overlapping nodes down.
              nodes.sort(ascendingDepth);
              for (i = 0; i < n; ++i) {
                node = nodes[i];
                dy = y0 - node.y;
                if (dy > 0) node.y += dy;
                y0 = node.y + node.dy + nodePadding;
              }

              // If the bottommost node goes outside the bounds, push it back up.
              dy = y0 - nodePadding - size[1];
              if (dy > 0) {
                y0 = node.y -= dy;

                // Push any overlapping nodes back up.
                for (i = n - 2; i >= 0; --i) {
                  node = nodes[i];
                  dy = node.y + node.dy + nodePadding - y0;
                  if (dy > 0) node.y -= dy;
                  y0 = node.y;
                }
              }
            });
          }

          function ascendingDepth(a, b) {
            return a.y - b.y;
          }
        }

        function computeLinkDepths() {
          nodes.forEach(function (node) {
            node.sourceLinks.sort(ascendingTargetDepth);
            node.targetLinks.sort(ascendingSourceDepth);
          });
          nodes.forEach(function (node) {
            var sy = 0,
                ty = 0;
            node.sourceLinks.forEach(function (link) {
              link.sy = sy;
              sy += link.dy;
            });
            node.targetLinks.forEach(function (link) {
              link.ty = ty;
              ty += link.dy;
            });
          });

          function ascendingSourceDepth(a, b) {
            return a.source.y - b.source.y;
          }

          function ascendingTargetDepth(a, b) {
            return a.target.y - b.target.y;
          }
        }

        function center(node) {
          return node.y + node.dy / 2;
        }

        function value(link) {
          return link.value;
        }

        return sankey;
      };


      const colorScheme = [
        "#aec7e8", // CCU
        "#ff7f0e", // CSRU
        "#ffbb78", // MICU
        "#2ca02c", // NICU
        "#98df8a", // NWARD
        "#d62728", // SICU
        "#ff9896" // TSICU
      ];
      const numOfWards = 7;

      const formatNumber = d3.format(".0%");
      const format = d => `${formatNumber(d)}`;
      const color = d3.scale.category20();

      svg.attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(${margin.left}, ${margin.top})`);

      let sankey = layout().nodeWidth(15)
          .nodePadding(10)
          .size([width, height]);

      let path = sankey.link();

      d3.json(sankeyDataUrl, graph => {
        sankey.nodes(graph.nodes)
            .links(graph.links)
            .layout(32);

        let link = svg
            .append("g")
            .selectAll(".link")
            .data(graph.links)
            .enter()
            .append("path")
            .attr("class", "link")
            .attr("d", path)
            .style("stroke-width", d => Math.max(1, d.dy))
            .style({
              fill: "none",
              stroke: "#000",
              "stroke-opacity": 0.15
            })
            .sort((a, b) => b.dy - a.dy);

        link.on("mouseover", function () {
          d3.select(this).style("stroke-opacity", 0.25);
        })
            .on("mouseout", function () {
              d3.select(this).style("stroke-opacity", 0.15);
            });

        link.append("title")
            .text(
                d =>
                    `${format(d.label)} of ${d.source.name} → ${d.target.name}\n${format(
                        d.value
                    )} of ${graph.nodes[0].name}`
            );

        let node = svg.append("g")
            .selectAll(".node")
            .data(graph.nodes)
            .enter()
            .append("g")
            .attr("class", "node")
            .attr("transform", d => `translate(${d.x}, ${d.y})`)
            .call(
                d3.behavior
                    .drag()
                    .origin(d => d)
                    .on("dragstart", function () {
                      this.parentNode.appendChild(this);
                    })
                    .on("drag", dragmove)
            );

        node.append("rect")
            .attr("height", d => d.dy)
            .attr("width", sankey.nodeWidth())
            .style("fill", (d, i) => {
              let c;
              if (i == 0) {
                c = "#1f77b4"; // Admit
              } else if (i == 15) {
                c = "#c7c7c7"; // Discharge
              } else {
                c = colorScheme[i % numOfWards];
              }

              return c;
            })
            .style({
              stroke: "none",
              cursor: "move",
              "fill-opacity": 0.9,
              "shape-rendering": "crispEdges"
            })
            .append("title")
            .text(d => `${format(d.value)} ${d.name}`);

        node.append("text")
            .attr("x", -6)
            .attr("y", d => d.dy / 2)
            .attr("dy", ".35em")
            .attr("text-anchor", "end")
            .attr("transform", null)
            .style({
              "pointer-events": "none",
              "text-shadow": "0 1px 0 #fff",
              "font-size": "12px"
            })
            .text(d => d.name)
            .filter(d => d.x < width / 2)
            .attr("x", 6 + sankey.nodeWidth())
            .attr("text-anchor", "start")
            .style("font-size", "12px");

        function dragmove(d) {
          d3.select(this).attr(
              "transform",
              `translate(${d.x}, ${(d.y = Math.max(
                  0,
                  Math.min(height - d.dy, d3.event.y)
              ))})`
          );
          sankey.relayout();
          link.attr("d", path);
        }

        let linkExtent = d3.extent(graph.links, d => d.value);

        const frequencyScale = d3.scale
            .linear()
            .domain(linkExtent)
            .range([0.05, 1]);

        d3.scale
            .linear()
            .domain(linkExtent)
            .range([1, 5]);

        graph.links.forEach(currentLink => {
          currentLink.freq = frequencyScale(currentLink.value);
          currentLink.particleSize = 2;
          currentLink.particleColor = d3.scale
              .linear()
              .domain([0, 1])
              .range([currentLink.source.color, currentLink.target.color]);
        });

        var sankeyD3Timer = d3.timer(tick, 1100);
        let particles = [];

        function tick(elapsed /* , time */) {
          try {
            particles = particles.filter(d => d.current < d.path.getTotalLength());

            d3.selectAll("path.link").each(function (d) {
              for (let x = 0; x < 2; x++) {
                const offset = (Math.random() - 0.5) * (d.dy - 4);
                if (Math.random() < d.freq) {
                  const length = this.getTotalLength();
                  particles.push({
                    link: d,
                    time: elapsed,
                    offset,
                    path: this,
                    length,
                    animateTime: length,
                    speed: 0.5 + Math.random()
                  });
                }
              }
            });
            particleEdgeCanvasPath(elapsed);

            if (elapsed > 180800) sankeyD3Timer.stop();

          } catch (err) {
            console.warn("Warning: particleEdgeCanvasPath function cannot find path");
            try{
              sankeyD3Timer.stop();
            } catch (timerErr) {
              console.warn("Warning: Sankey timer is undefined");
            }
            return true;
          }
        }

        function particleEdgeCanvasPath(elapsed) {
          if (!selectedCanvas) return;
          let context = selectedCanvas.node().getContext("2d");

          context.clearRect(0, 0, width, height);
          context.fillStyle = "gray";
          context.lineWidth = "1px";
          for (const x in particles) {
            if ({}.hasOwnProperty.call(particles, x)) {
              const currentTime = elapsed - particles[x].time;
              particles[x].current = currentTime * 0.15 * particles[x].speed;
              const currentPos = particles[x].path.getPointAtLength(
                  particles[x].current
              );
              context.beginPath();
              context.fillStyle = particles[x].link.particleColor(0);
              context.arc(
                  currentPos.x,
                  currentPos.y + particles[x].offset,
                  particles[x].link.particleSize,
                  0,
                  2 * Math.PI
              );
              context.fill();
            }
          }
        }
      });
    }

    // Runs each time the DOM window resize event fires.
    // Resets the canvas dimensions to match window,
    // then draws the new borders accordingly.
    function resizeCanvas() {
      try {
        if (!selectedCanvas) return;
        let htmlCanvas = selectedCanvas.node().getContext("2d");

        htmlCanvas.width = chartDiv.clientWidth < 1000 ? chartDiv.clientWidth : 1000;
        htmlCanvas.height = sankeyDivHeight;
        redraw();

        selectedCanvas.node().width = chartDiv.clientWidth < 1000 ? chartDiv.clientWidth : 1000;
        selectedCanvas.node().height = sankeyDivHeight;
      } catch (e) {
        console.error("Error while selecting canvas: "+ e);
        redraw();
      }
    }
  };
}
export default PatientFlowMimicSankeyDiagram;
