import React from "react";
import {graphql} from "gatsby";
import "../../../../src/scss/kollstrap.scss";
import {CircleLoader} from "react-spinners";

import * as d3v5 from "d3v5";
import HeatMapSVG from "./heatmapsvg";
import {rhythm, scale} from "../../../../src/utils/typography";

class PatientFlowMimicHeatMapDiagram extends React.Component {
  componentDidMount() {
    this.drawChart();
  }

  drawChart() {
    this.heatmapViz(d3v5);
  }

  render() {
    return (
        <div>
          <div className="row">
            <div className="col">
              <div className="inputchart border-primary mt-3 mb-3"
                   dangerouslySetInnerHTML={{
                     __html: ' <input type="checkbox" className="myCheckbox" value="Monday"> Monday</input> <input type="checkbox" className="myCheckbox" value="Tuesday"> Tuesday</input> <input type="checkbox" className="myCheckbox" value="Wednesday"> Wednesday</input> <input type="checkbox" className="myCheckbox" value="Thursday"> Thursday</input> <input type="checkbox" className="myCheckbox" value="Friday"> Friday</input> <input type="checkbox" className="myCheckbox" value="Saturday"> Saturday</input> <input type="checkbox" className="myCheckbox" value="Sunday"> Sunday</input>'
                   }}>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col col-lg-10">
              <div id="heatmap"
                   className="hmchart mt-1 mb-3">
                <HeatMapSVG/>
              </div>
            </div>
          </div>
        </div>
    );
  }

  heatmapViz(d3, heatmapData) {
    const heatmapDataUrl = "https://curiousstemlovingfellow-blog-data.s3.amazonaws.com/blog-posts/heatmap.csv";
    const heatmapDivHeight = 420;
    var svg = d3.select("#heatmap svg");
    let chartDiv = document.getElementById("heatmap");
    let heatmapDivWidth = chartDiv.clientWidth < 1000 ? chartDiv.clientWidth : 1000;

    /*
     * Dynamically resize canvas
     * --note: in this case there is no html canvas element, the resize is for svg elements
     */
    (function () {
      // Start listening to resize events and draw canvas.
      // Register an event listener to call the resizeCanvas() function
      window.addEventListener("resize", resizeCanvas, false);
      resizeCanvas();
    })();

    function redraw() {
      svg.selectAll("*").remove();
      d3.csv(heatmapDataUrl, function (d) {
        return {
          CareUnit: d.currCareunit,
          Hour: +d.HOUR,
          IDCount: +d.icustayId,
          DayOfWeek: d.DAY_OF_WEEK,
          AvgCount: +d.AVG_PATIENTS
        };
      }).then(function (data) {
        // agg data
        let units = d3.map(data, function (d) {
              return d.CareUnit;
            }).keys();
        let hours = d3.map(data, function (d) {
              return d.Hour;
            }).keys();
        let data_roll = [];
        for (let i in units) {
          for (let j in hours) {
            let a = d3.mean(
                data.filter(function (d) {
                  return d.CareUnit == units[i] && d.Hour == hours[j];
                }),
                function (d) {
                  return d.AvgCount;
                }
            );
            data_roll.push({
              CareUnit: units[i],
              Hour: hours[j],
              AvgCount: +a
            });
          }
        }

        d3.selectAll("input").on("change", function (y) {
          let choices = [];
          // get day of week choices
          d3.selectAll("input").each(function (d) {
            let cb = d3.select(this);
            if (cb.property("checked")) {
              choices.push(cb.property("value"));
            }
          });
          let data2 = {};
          if (choices.length == 0) {
            data2 = data;
          } else {
            data2 = data.filter(function (d) {
              return choices.includes(d.DayOfWeek);
            });
          }

          let data_roll = [];
          for (let i in units) {
            for (let j in hours) {
              let a = d3.mean(
                  data2.filter(function (d) {
                    return d.CareUnit == units[i] && d.Hour == hours[j];
                  }),
                  function (d) {
                    return d.AvgCount;
                  }
              );
              data_roll.push({
                CareUnit: units[i],
                Hour: hours[j],
                AvgCount: +a
              });
            }
          }

          // update color scale
          let colorDomain = d3.range(0,
              d3.max(data_roll, function (d) {
                return d.AvgCount;
              }),
              d3.max(data_roll, function (d) {
                return d.AvgCount;
              }) / 5
          );

          let z = d3.scaleThreshold()
              .domain(colorDomain.slice(1, 5))
              .range(d3.schemeOranges[5]);

          // update viz
          svg.selectAll(".tile")
              .data(data_roll)
              .transition()
              .duration(0)
              .attr("fill", function (d) {
                return z(d.AvgCount);
              });

          // update legend counts
          legend
              .select("text")
              .data(colorDomain.reverse())
              .transition()
              .duration(0)
              .attr("x", 26)
              .attr("y", 10)
              .attr("dy", ".35em")
              .text(function (d) {
                return "\u2265" + " " + d3.format(".0f")(d * 1000);
              });
        });

        // fig size with margins
        var margin = {top: 50, right: 80, bottom: 50, left: 80},
            width = heatmapDivWidth - margin.left - margin.right,
            height = heatmapDivHeight - margin.top - margin.bottom;

        svg.attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // scales
        var x = d3.scaleBand().domain(d3.map(data_roll,
            function (d) {
                  return d.Hour;
            }).keys()).range([0, width]);

        var y = d3.scaleBand().domain(
                d3.map(data_roll, function (d) {
                      return d.CareUnit;
                    }).keys()
            ).range([height, 0].reverse());

        let colorDomain = d3.range(0,
            d3.max(data_roll, function (d) {
              return d.AvgCount;
            }),
            d3.max(data_roll, function (d) {
              return d.AvgCount;
            }) / 5
        );

        var z = d3.scaleThreshold().domain(colorDomain.slice(1, 5)).range(d3.schemeOranges[5]);

        // display boxes
        svg.selectAll(".tile")
            .data(data_roll)
            .enter()
            .append("rect")
            .attr("class", "tile")
            .attr("x", function (d) {
              return x(d.Hour);
            })
            .attr("y", function (d) {
              return y(d.CareUnit);
            })
            .attr("width", x.bandwidth())
            .attr("height", y.bandwidth())
            .attr("fill", function (d) {
              return z(d.AvgCount);
            })
            .attr("stroke", "black");

        // legend
        let legend = svg
            .selectAll(".legend")
            .data(colorDomain.reverse())
            .enter()
            .append("g")
            .attr("class", "legend")
            .attr("transform", function (d, i) {
              return "translate(" + (width + 20) + "," + (20 + i * 20) + ")";
            });

        legend
            .append("rect")
            .attr("width", 20)
            .attr("height", 20)
            .style("fill", function (d, i) {
              return d3.schemeOranges[5][5 - i];
            })
            .attr("stroke", "black");

        legend
            .append("text")
            .attr("x", 26)
            .attr("y", 10)
            .attr("dy", ".35em")
            .text(function (d) {
              return "\u2265" + " " + d3.format(".0f")(d * 1000);
            });

        svg.append("text")
            .attr("class", "label")
            .attr("x", width + 20)
            .attr("y", 10)
            .attr("dy", ".35em")
            .text("Count");

        // x axis
        svg.append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + height + ")")
            .attr("stroke-width", "0")
            .call(d3.axisBottom(x))
            .append("text")
            .attr("class", "label")
            .attr("x", width / 2)
            .attr("y", 40)
            .attr("text-anchor", "beginning")
            .attr("fill", "black")
            .attr("font-family", "sans-serif")
            .attr("font-weight", "bold")
            .text("Hour of Day");

        // y axis
        svg.append("g")
            .attr("class", "y axis")
            .attr("stroke-width", "0")
            .call(d3.axisLeft(y))
            .append("text")
            .attr("class", "label")
            .attr("x", -height / 2)
            .attr("y", -80)
            .attr("text-anchor", "middle")
            .attr("transform", "rotate(-90)")
            .attr("fill", "black")
            .attr("font-weight", "bold")
            .text("Unit");

        // title
        svg.append("text")
            .attr("x", width / 2)
            .attr("y", -10)
            .attr("text-anchor", "middle")
            .attr("font-weight", "bolder")
            .style("font-size", "18px")
            .text("Average New Patient Count by Unit and Hour (per 1,000)");
      });
    }

    // Runs each time the DOM window resize event fires.
    // redraws the heatmap
    function resizeCanvas() {
      try {
        redraw();
      } catch (e) {
        console.error("Error while redrawing heat map: " + e);
        redraw();
      }
    }
  }
}

export default PatientFlowMimicHeatMapDiagram;
